import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="privacy-container">
        <h3>Privacy Policy for Bhoomicam Private Limited</h3>
        <p>
          This Privacy Policy applies to all of our services including website
          and all applications with regards to the information that they shared
          and/or collect in Bhoomicam Private Limited.
        </p>
        <p>
          Bhoomicam Private Limited has privacy policies in place that predicts
          the scope to collect, use, maintain, and disclose the information by
          any means shared on our website/applications during usage by
          users/buyers/potential clients.
        </p>
        <p>
          Note: Our privacy policy is subject to change at any time without
          notice. To make sure you are aware of any changes, please review this
          policy periodically.
        </p>
        <h4>Consent</h4>
        <p>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </p>
        <h4>PERSONAL INFORMATION</h4>
        <p>
          Private information of users/buyers/potential clients may be collected
          during access to our applications or by various means, when a visitor
          pays a visit to our webtool/website.
        </p>
        <p>
          A visitor may be asked for personal information such as name, mail-id,
          contact number, current address, aadhar number, etc., or are practical
          to access different sections of services. Users may access our
          services by skipping optional information and may be refused certain
          sections of services if he/she chose not to reveal the mandatory
          fields.
        </p>
        <h4>Sharing of personal information</h4>
        <p>
          We may share personal information with our other corporate entities
          and affiliates. These entities and affiliates may market to you as a
          result of such sharing unless you explicitly opt-out. We may disclose
          personal information to third parties. This disclosure may be required
          for us to provide you access to our Services, to comply with our legal
          obligations, to enforce our User Agreement, to facilitate our
          marketing and advertising activities, or to prevent, detect, mitigate,
          and investigate fraudulent or illegal activities related to our
          Services. We do not disclose your personal information to third
          parties for their marketing and advertising purposes without your
          explicit consent.
        </p>
        <h4>NON-PERSONAL IDENTIFICATION INFORMATION</h4>
        <p>
          We may obtain other relevant technical information such as browser
          name, the type of computer, means of connection to our Sites,
          operating system, and the Internet service providers and other similar
          information utilized while accessing our services.
        </p>
        <h4>CONSENT AND DISCLOSURE OF INFORMATION</h4>
        <p>
          When a user furnishes any information during various stages to gain
          access to our services/applications, including completion of a
          transaction (such as placing an order, making a payment, etc.), it may
          carry confidential and sensitive information of debit card, credit
          card,UPI details or other devices of payment, which would be taken as
          a mutual consensus to dispense information to meet the bilateral
          interest.
        </p>
        <p>
          Any information of the user may be shared with the competent authority
          if he/she is found to be indulged in any scrupulous, illicit
          activities or in violation of terms and conditions of Bhoomicam
          Private Limited.
        </p>
        <h4>PROTECTION OF INFORMATION</h4>
        <p>
          We have the best technology in place to secure the confidential
          information of users against unauthorized access, alteration,
          disclosure, or destruction of your personal information.
        </p>
        <h4>COLLECTED INFORMATION CAN BE UTILIZED FOR THE FOLLOWING PURPOSE</h4>
        <ul>
          <li>
            The collected information can be used to understand and improve the
            user’s experience.
          </li>
          <li>To improve the interface of our website/application.</li>
          <li>
            Consolidated data may be used as a tool to enhance the efficacy of
            our website/applications to meet the expectation of users.
          </li>
          <li>To render seamless customer experience.</li>
          <li>
            The aggregate data can be a source to address the concern to ensure
            the most distinguished satisfactory services among customers.
          </li>
          <li>
            Information of users can be used to run an awareness campaign or
            share information about the new product, event, or development
            subjected to the consent of the user.
          </li>
          <li>To send notification through applications.</li>
          <li>
            Users may get a notification by messange on their phone number or
            email address pertaining to their order, payment, inquiry, etc.
            He/she may opt-out of the notification services by writing to us or
            by other available methods.
          </li>
        </ul>
     
        <h3 style={{marginTop:20}}>Bhoomicam’s Cancellation Policy:</h3>
        <ul>
          <li>
            Bhoomicam believes in 100% customer satisfaction and has therefore a
            liberal cancellation policy.
          </li>
          <li>
            You can cancel the orderof renting within 12 hours once it's placed.
          </li>
        </ul>
        <h3 style={{marginTop:20}}>Bhoomicam’s Refund and Exchange Policy:</h3>
        <ul>
          <li>
            Bhoomicam believes in 100% customer satisfaction and has therefore a
            liberal refund policy.
          </li>
          <li>
            If the product you received is damaged or expired, you can mail us.
          </li>
          <li>
            We will try our best to rectify it; in case the product is
            unavailable, we will initiate a refund.
          </li>
          <li>
            Once your complaint is registered, we will contact you informing
            further processes.
          </li>
        </ul>
        <h4>I have received a wrong / defective product. How do I proceed?</h4>
        <p>
          Just mail us, we will take care of the rest and keep you updated on
          the process.
        </p>
        <h4>Shipment Policy:</h4>
        <p>We donot do shipping as of now of any products.</p>
        <h4>What if the product is received in damaged condition?</h4>
        <p>
          If you think you have received the product in a bad condition or if
          the packaging is tampered with or damaged before delivery, please
          refuse to accept the package and return the package to the delivery
          person. Also, please email us at{" "}
          <a href="mailto:hello@bhoomicam.com">hello@bhoomicam.com</a>{" "}
          mentioning your Order ID. We will personally ensure that a brand new
          replacement is issued to you with no additional cost.
        </p>
        <h3>CHANGES AND ACCEPTANCE OF PRIVACY POLICY</h3>
        <p>
          Bhoomicam Private Limited reserves the right to change the privacy
          policies subjected to the administration’s discretion at any given
          point in time. The access to the website or its services by the user
          shall be considered as acceptance of various policies. If the user is
          not willing to abide by policies, he/she would restrain him/herself
          from using the website/applications. Users are requested to read
          thoroughly to the governing policies frequently and stand acknowledged
          and agreed before they access any content on the website or
          applications.
        </p>{" "}
        <h4>Contact Us</h4>
        <p>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at{" "}
          <p>
            BHOOMICAM PRIVATE LIMITED C/O SIDDHARTHA KHARE 20 KK PURI COLONY,
            JHANSINANDAN PURA, JHANSI, Jhansi Uttar Pradesh ,India, 284001
          </p>
          <p>Mobile Number : +91 7505461499</p>
          <p>Contact Email : hello@bhoomicam.com</p>
        </p>
      </div>

      <div className="privacy-container">
        <h2 style={{ marginTop: 10 }}>
          Refund Policy for BhoomiFresh Application
        </h2>
        <p>
          We at Bhoomicam make great efforts to give you quality products of
          your choice. However if the product you received is damaged or
          expired, you can mail us at hello@bhoomicam.com.
        </p>

        <h4>Bhoomicam’s Cancellation Policy:</h4>
        <ul>
          <li>
            Bhoomicam believes in 100% customer satisfaction and has therefore a
            liberal cancellation policy.
          </li>
          <li>You can cancel the order within 12 hours once it's placed.</li>
        </ul>
        <h4>Bhoomicam’s Refund and Exchange Policy:</h4>
        <ul>
          <li>
            Bhoomicam believes in 100% customer satisfaction and has therefore a
            liberal refund policy.
          </li>
          <li>
            If the product you received is damaged or expired, you can mail us.
          </li>
          <li>
            We will try our best to replace it; in case the product is
            unavailable, we will initiate a refund.
          </li>
          <li>However, opened/used food products cannot be exchanged.</li>
          <li>
            Once your complaint is registered, we will contact you informing
            further processes.
          </li>
        </ul>

        <h4>I have received a wrong / defective product. How do I proceed?</h4>
        <p>
          Just mail us, we will take care of the rest and keep you updated on
          the process.
        </p>

        <h4>Shipment Policy:</h4>
        <p>We donot do shipping as of now of any products.</p>

        <h4>What if the product is received in damaged condition?</h4>
        <p>
          If you think you have received the product in a bad condition or if
          the packaging is tampered with or damaged before delivery, please
          refuse to accept the package and return the package to the delivery
          person. Also, please email us at{" "}
          <a href="mailto:hello@bhoomicam.com">hello@bhoomicam.com</a>{" "}
          mentioning your Order ID. We will personally ensure that a brand new
          replacement is issued to you with no additional cost.
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;

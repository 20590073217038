import React from 'react'

const UserHelp = () => {

    
  return (
    
<div class="container-userHelp">
  <div class="steps-userHelp">
    <p>
      Steps for users to reach out to Bhoomicam Private Limited for deleting their all information with Bhoomicam:
    </p>
    <br />
    <ol>
      <li>Write a mail to <a href="mailto:hello@bhoomicam.com">hello@bhoomicam.com</a>.</li>
      <li>Mention all the necessary information for which user intends to delete all information in the mail.</li>
      <li>We will shortly reply to the mail.</li>
    </ol>
  </div>

  <div class="teamInfo-userHelp">
    <p>Bhoomicam Private Limited | All Rights Reserved</p>
  </div>
</div>
  )
}

export default UserHelp

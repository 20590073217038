import React from 'react'

const Terms = () => {
  return (
    <div className='terms-container'>
      <h2><strong>Terms and Conditions</strong></h2>

<p>Welcome to Bhoomicam Private Limited!</p>

<p>These terms and conditions outline the rules and regulations for the use of Bhoomicam's website and application and Bhoomicam Application, located at https://bhoomicam.com/.</p>

<p>Please read the following terms and conditions very carefully as your use of service is subject to your acceptance of and compliance with the following terms and conditions (“Terms”).</p>

<p>This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.
</p>

<p>This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of www.bhoomicam.com website and application.
By subscribing to or using any of our services you agree that you have read, understood and are bound by the Terms, regardless of how you subscribe to or use the services. If you do not want to be bound by the Terms, you must not subscribe to or use our services. These Terms and various other policies are binding as per the provisions of the Information Technology (Intermediaries guidelines) Rules, 2011 formulated under the Information Technology Act of 2000.</p>

<p>The domain name www.bhoomicam.com (hereinafter referred to as “Website”) is owned by bhoomicam with its registered office at TIDES, Bio-Incubator, 2nd & 3rd Floor, Multi-activity Center Indian Institute of Technology, Roorkee, Uttarakhand, 247667 (hereinafter referred to as “bhoomicam”).</p>
 
<p>Your use of the website and application and services and tools are governed by the following terms and conditions (“Terms of Use”) as applicable to the website and application including the applicable policies which are incorporated herein by way of reference. If You transact on the website and application, You shall be subject to the policies that are applicable to the website and application for such transaction. By mere use of the website and application, You shall be contracting with bhoomicam and these terms and conditions including the policies constitute Your binding obligations, with bhoomicam.</p>

<p>
For the purpose of these Terms of Use, wherever the context so requires “You” or “User” shall mean any natural or legal person who has agreed to become a buyer on the application by providing Registration Data while registering on the  application as Registered User using the computer systems. Bhoomicam allows the User to surf the website and application or making purchases without registering on the website and application. The term “We”, “Us”, “Our” shall mean Bhoomicam.
</p>

<p>When You use any of the services provided by Us through the website and application, including but not limited to, (e.g. Product Reviews, Seller Reviews), You will be subject to the rules, guidelines, policies, terms, and conditions applicable to such service, and they shall be deemed to be incorporated into this Terms of Use and shall be considered as part and parcel of this Terms of Use. We reserve the right, at Our sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without any prior written notice to You. It is Your responsibility to review these Terms of Use periodically for updates / changes. Your continued use of the website and application following the posting of changes will mean that You accept and agree to the revisions. As long as You comply with these Terms of Use, We grant You a personal, non-exclusive, non-transferable, limited privilege to enter and use the website and application.
ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. By impliedly or expressly accepting these Terms of Use, You also accept and agree to be bound by Bhoomicam Policies (including but not limited to Privacy Policy) as amended from time to time.
</p>
<p>Services Offered:</p>

{/* <p>Bhoomicam.com  provides a number of Internet-based services through the Applications (all such services, collectively, the “Service”). One such service enables users to purchase original merchandise such as artwork, home décor, clothing, footwear and accessories from various designers (collectively, “Products”).The Products can be purchased through the website and application through various methods of payments offered. The sale/purchase of Products shall be additionally governed by specific policies of sale, like cancellation policy, return policy, etc., and all of which are incorporated here by reference. In addition, these terms and policies may be further supplemented by Product specific conditions, which may be displayed on the webpage of that Product.</p> */}

<p>User Account, Password, and Security:</p>
{/* <p>You will receive a password and account designation upon completing the application’s registration process. You are responsible for maintaining the confidentiality of the password and account, and are fully responsible for all activities that occur under your password or account. You agree to (a) immediately notify bhoomicam.com of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session. bhoomicam.com cannot and will not be liable for any loss or damage arising from your failure to comply with this Section 2.</p> */}


<p>You agree that if You provide any information that is untrue, inaccurate, not current or incomplete or We have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with the this Terms of Use, We shall have the right to indefinitely suspend or terminate or block access of your membership on the  application and refuse to provide You with access to application.
Communications</p>

<p>When You use the  application or send emails or other data, information or communication to us, You agree and understand that You are communicating with Us through electronic records and You consent to receive communications via electronic records from Us periodically and as and when required. We may communicate with you by email or SMS or by such other mode of communication, electronic or otherwise.
Platform for Transaction and Communication</p>

{/* <p>The website and application is a platform that Users utilize to meet and interact with one another for their transactions. bhoomicam is not and cannot be a party to or control in any manner any transaction between the website and application’s Users.</p> */}

<ul>
  <li>
    All commercial/contractual terms are offered by and agreed to between Buyers and Sellers alone. The commercial/contractual terms include without limitation price, shipping costs, payment methods, payment terms, date, period and mode of delivery, warranties related to products and services and after sales services related to products and services. bhoomicam does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between the Buyers and Sellers.
  </li>
  <li>
    bhoomicam does not make any representation or Warranty as to specifics (such as quality, value, salability, etc) of the products or services proposed to be sold or offered to be sold or purchased on the website and application. bhoomicam does not implicitly or explicitly support or endorse the sale or purchase of any products or services on the website and application. bhoomicam accepts no liability for any errors or omissions, whether on behalf of itself or third parties.
  </li>
  <li>
    bhoomicam is not responsible for any non-performance or breach of any contract entered into between Buyers and Sellers. bhoomicam Makes cannot and does not guarantee that the concerned Buyers and/or Sellers will perform any transaction concluded on the website and application. bhoomicam shall not and is not required to mediate or resolve any dispute or disagreement between Buyers and Sellers.
  </li>
  <li>
    bhoomicam does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc) of any of its Users. You are advised to independently verify the bona fides of any particular User that You choose to deal with on the website and application and use Your best judgment in that behalf.
  </li>
  <li>
    bhoomicam does not at any point of time during any transaction between Buyer and Seller on the website and application take possession of any of the products or services offered by Seller nor does it at any point gain title to or have any rights therein, offered by Seller to Buyer.
  </li>
  <li>
    At no time shall bhoomicam hold any right, title or interest over the products nor shall bhoomicam have any obligations or liabilities in respect of such contract entered into between Buyers and Sellers. bhoomicam is not responsible for unsatisfactory or delayed performance of services or damages or delays as a result of products which are out of stock, unavailable or back ordered.
  </li>
  <li>
    The website and application is only a platform that can be utilized by Users to reach a larger base to buy and sell products or services. bhoomicam is only providing a platform for communication and it is agreed that the contract for sale of any of the products or services shall be a strictly bipartite contract between the Seller and the Buyer.
  </li>
  <li>
    At no time shall bhoomicam hold any right, title or interest over the products nor shall bhoomicam have any obligations or liabilities in respect of such contract.
  </li>
  <li>
    bhoomicam is not responsible for unsatisfactory or delayed performance of services or damages or delays as a result of products which are out of stock, unavailable or back ordered.
  </li>
</ul>
<ul>
  <li>
    <strong>Charges</strong>
  </li>
  <li>
    Membership on the website and application is free for buyers. bhoomicam does not charge any fee for browsing and buying on the website and application. bhoomicam reserves the right to change its Fee Policy from time to time. In particular, bhoomicam may at its sole discretion introduce new services and modify some or all of the existing services offered on the website and application.
  </li>
</ul>
<ul>
  <li>
    <strong>Use of the website and application</strong>
  </li>
  <li>
    You agree, undertake and confirm that Your use of website and application shall be strictly governed by the following binding principles:
  </li>
  <li>
    <strong>1. You shall not host, display, upload, modify, publish, transmit, update or share any information which:</strong>
    <ul>
      <li>
        (a) belongs to another person and to which You do not have any right to;
      </li>
      <li>
        (b) is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing, including but not limited to “indecent representation of women” within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;
      </li>
      <li>
        (c) is misleading in any way;
      </li>
      <li>
        (d) is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, pedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual;
      </li>
      <li>
        (e) harasses or advocates harassment of another person;
      </li>
      <li>
        (f) involves the transmission of “junk mail”, “chain letters”, or unsolicited mass mailing or “spamming”;
      </li>
      <li>
        (g) promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libelous;
      </li>
      <li>
        (h) infringes upon or violates any third party’s rights [including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person’s name, email address, physical address or phone number) or rights of publicity];
      </li>
      <li>
        (i) promotes an illegal or unauthorized copy of another person’s copyrighted work (see “Copyright complaint” below for instructions on how to lodge a complaint about uploaded copyrighted material), such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;
      </li>
      <li>
        (j) contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page);
      </li>
      <li>
        (k) provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;
      </li>
      <li>
        (l) provides instructional information about illegal activities such as making or buying illegal weapons, violating someone’s privacy, or providing or creating computer viruses;
      </li>
      <li>
        (m) contains video, photographs, or images of another person (with a minor or an adult).
      </li>
      <li>
        (n) tries to gain unauthorized access or exceeds the scope of authorized access to the website and application or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the website and application or solicits passwords or personal identifying information for commercial or unlawful purposes from other users;
</li>
<li>
    (o) engages in commercial activities and/or sales without Our prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of “virtual” products related to the website and application. Throughout this Terms of Use, bhoomicam’ prior written consent means a communication coming from bhoomicam’ Legal Department, specifically in response to Your request, and specifically addressing the activity or conduct for which You seek authorization;
  </li>
  <li>
    (p) solicits gambling or engages in any gambling activity which We, in Our sole discretion, believe is or could be construed as being illegal;
  </li>
  <li>
    (q) interferes with another USER’s use and enjoyment of the website and application or any other individual’s User and enjoyment of similar services;
  </li>
  <li>
    (r) refers to any website and application or URL that, in Our sole discretion, contains material that is inappropriate for the website and application or any other website and application, contains content that would be prohibited or violates the letter or spirit of these Terms of Use.
  </li>
  <li>
    (s) harm minors in any way;
  </li>
  <li>
    (t) infringes any patent, trademark, copyright or other proprietary rights or third party’s trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen products;
  </li>
  <li>
    (u) violates any law for the time being in force;
  </li>
  <li>
    (v) deceives or misleads the addressee/ users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;
  </li>
  <li>
    (w) impersonate another person;
  </li>
  <li>
    (x) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancel bots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;
  </li>
  <li>
    (y) threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.
  </li>
  <li>
    (z) shall not be false, inaccurate or misleading;
  </li>
  <li>
    (aa) shall not, directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force.
  </li>
  <li>
    (ab) shall not create liability for Us or cause Us to lose (in whole or in part) the services of Our internet service provider (“ISPs”) or other suppliers;
  </li>
</ul>
<li>
    2. You shall not use any “deep-link”, “page-scrape”, “robot”, “spider” or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the website and application or any Content, or in any way reproduce or circumvent the navigational structure or presentation of the website and application or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the website and application. We reserve Our right to bar any such activity.
  </li>
  <li>
    3. You shall not attempt to gain unauthorized access to any portion or feature of the website and application, or any other systems or networks connected to the website and application or to any server, computer, network, or to any of the services offered on or through the website and application, by hacking, password “mining” or any other illegitimate means.
  </li>
  <li>
    4. You shall not probe, scan or test the vulnerability of the website and application or any network connected to the website and application nor breach the security or authentication measures on the website and application or any network connected to the website and application. You may not reverse look-up, trace or seek to trace any information on any other User of or visitor to website and application, or any other customer, including any account on the website and application not owned by You, to its source, or exploit the website and application or any service or information made available or offered by or through the website and application, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than Your own information, as provided for by the website and application.
  </li>
  <li>
    5. You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about Us or the brand name or domain name used by Us including the terms bhoomicam, bhoomicam.com, or otherwise engage in any conduct or action that might tarnish the image or reputation, of bhoomicam or sellers on platform or otherwise tarnish or dilute any bhoomicam’ trade or service marks, trade name and/or goodwill associated with such trade or service marks, trade name as may be owned or used by us. You agree that You will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the website and application or bhoomicam systems or networks, or any systems or networks connected to bhoomicam.
  </li>
  <li>
    <strong>6. You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the website and application or any</strong>
  </li>
</li>

</ul>

<p>By accessing this website and application/application we assume you accept these terms and conditions. Do not continue to use Bhoomicam if you do not agree to take all of the terms and conditions stated on this page.</p>
   <p>WE DO NOT PROVIDE ANY TYPE OF GUARANTEE OR WARRANTY FOR ALL PRODUCTS/DAMAGE.NO RETURNS/REPLACEMENT/CANCELLATION,CLUBBING OF TWO ORDERS OR MODIFYING ALREADY PLACED ORDER IS NOT POSSIBLE.</p>
    </div>
  )
}

export default Terms
